/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import HeadingTag from "../shared/HeadingTag";
import AboutImage from "../assets/About/about.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImagePlaceholder from "../assets/image-placeholder.jpeg";
export default function About() {
    return (
        <div className="w-full px-4 py-8 pt-10 dark:bg-gray-900" id="about">
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag
                        text="About Us"
                        heading="Kami hadir untuk membantu mewujudkan mimpi podcastmu menjadi
            kenyataan."
                    />
                </div>

                <div className="grid grid-cols-1 space-y-6 md:grid-cols-2 md:space-y-0 md:space-x-20">
                    <div>
                        <div className="text-base leading-loose font-dmsans text-slate-500">
                            <p className="mb-6 text-justify">
                                Injabar Podcast Studio adalah ruang studio
                                multifungsi dengan fasilitas dan peralatan
                                rekaman lengkap yang dapat digunakan sebagai
                                ruang podcast atau event online dan ideal bagi
                                kalangan profesional maupun non-profesional.
                            </p>
                            <p className="mb-6 text-justify">
                                Kami memahami betapa pentingnya memiliki studio
                                yang berkualitas tinggi dan lengkap bagi para
                                pembuat podcast atau video lainnya, sehingga
                                kami hadir untuk membantu mewujudkan mimpi
                                podcastmu menjadi kenyataan. Dengan studio kami
                                yang modern dan dilengkapi dengan peralatan
                                terbaru, kamu dapat merasakan kenyamanan dan
                                kemudahan dalam proses rekaman. Kami menawarkan
                                beberapa paket sewa studio podcast yang dapat
                                disesuaikan dengan kebutuhanmu.
                            </p>
                            <p className="mb-6 text-justify">
                                Tak perlu khawatir akan kualitas suara, karena
                                studio kami dirancang untuk memberikan hasil
                                rekaman yang optimal dan berkualitas. Selain
                                itu, kamu juga dapat memilih beberapa tambahan
                                layanan seperti teknisi rekaman, jasa editing,
                                dan lainnya. Jangan biarkan keterbatasan
                                peralatan menghalangi mimpi menjadi creator.
                                Wujudkan mimpi podcastmu menjadi kenyataan
                                bersama Injabar Podcast Studio. Hubungi kami
                                sekarang untuk info lebih lanjut dan pemesanan
                                studio.
                            </p>
                        </div>
                        <button className="w-full px-8 py-4 mt-4 text-white transition duration-500 bg-black rounded-md md:max-w-fit font-dmsans hover:bg-slate-900 dark:hover:bg-gray-800">
                            <a href="#pricing">Lihat Pricelist</a>
                        </button>
                    </div>
                    <div className="flex">
                        <LazyLoadImage
                            src={AboutImage}
                            alt=""
                            className="items-center "
                            placeholderSrc={ImagePlaceholder}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
