/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import satu from "../assets/Logo Konten-01.png";
import dua from "../assets/Logo Konten-02.png";
import tiga from "../assets/Logo Konten-03.png";
import empat from "../assets/Logo Konten-04.png";
import lima from "../assets/Logo Konten-05.png";
import enam from "../assets/Logo Konten-06.png";
import tujuh from "../assets/Logo Konten-07.png";

export default function Hero() {
    return (
        <div className="w-full px-4 pt-4 dark:bg-gray-900" id="home">
            <div className="mx-auto bg-center bg-cover bg-heroBanner lg:max-w-7xl rounded-3xl">
                <div className="backdrop-opacity-90 bg-black/70 rounded-3xl">
                    <div className="max-w-4xl mx-auto space-y-4 text-center py-28">
                        <h4 className="mx-auto italic font-bold text-yellow-400 text-md">
                            Sewa Studio Podcast Bandung
                        </h4>
                        <h1 className="max-w-2xl mx-auto text-3xl font-bold text-white font-dmsans">
                            Wujudkan Mimpi Podcastmu menjadi Kenyataan bersama
                            Injabar Creative Studio
                        </h1>
                        <p className="max-w-2xl px-4 mx-auto text-slate-300 text-md font-dmsans">
                            Injabar Creative Studio adalah solusi terbaik untuk
                            mencapai hasil rekaman podcast yang profesional.
                            Kkami hadir untuk membantu mewujudkan mimpi
                            podcastmu menjadi kenyataan.
                        </p>
                        <button className="px-6 py-4 text-white transition duration-1000 bg-black rounded-md font-dmsans hover:bg-yellow-600 ">
                            Selengkapnya
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-auto mt-16 bg-center bg-cover lg:max-w-7xl rounded-3x">
                <div className="grid lg:grid-cols-7 md:grid-cols-3 ">
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <img src={satu} alt="" />
                    </div>
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <a href="https://injabar.unpad.ac.id/" target="_blank">
                            <img src={dua} alt="Injabar Unpad" />
                        </a>
                    </div>
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <a
                            href="https://www.youtube.com/watch?v=xQtJ6gRVsxc&ab_channel=INJABARTV"
                            target="_blank"
                        >
                            <img src={tiga} alt="Injabar Podcast" />
                        </a>
                    </div>
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <a
                            href="https://www.youtube.com/watch?v=SeTdkUXK03w&list=PL9XDlY_2tM52MjpfsqDY8Zi4jPYdRaoGj&index=15&ab_channel=INJABARTV"
                            target="_blank"
                        >
                            <img src={empat} alt="Injabar TV" />
                        </a>
                    </div>
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <a
                            href="https://youtube.com/playlist?list=PL9XDlY_2tM53sov6XXaG29cYNz5attWrf"
                            target="_blank"
                        >
                            <img src={lima} alt="Injabar Talkshow" />
                        </a>
                    </div>
                    <div className="flex justify-center align-middle transition duration-500 filter grayscale hover:filter-none">
                        <img src={enam} alt="" />
                    </div>
                    <div className="flex justify-center col-span-3 align-middle transition duration-500 filter grayscale hover:filter-none md:col-auto lg:col-auto">
                        <img src={tujuh} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
