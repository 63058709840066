import React from "react";
import HeadingTag from "../shared/HeadingTag";
import HeroImagePapandayan from "../assets/Room/papandayan/Hero.png";
import HeroImageGalunggung from "../assets/Room/galunggung/Hero.png";
import Lightbox from "../shared/Lightbox";
import { roomDataPapandayan, roomDataGalunggung } from "../data/roomData";
import {
    LazyLoadComponent,
    LazyLoadImage,
} from "react-lazy-load-image-component";
import ImagePlaceHolder from "../assets/image-placeholder.jpeg";

export default function Room() {
    return (
        <div className="w-full px-4 py-24 dark:bg-gray-900" id="room">
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag
                        text="Room Type"
                        heading="Pilihan tipe studio terbaik yang kami sediakan sesuai dengan kebutuhan anda "
                    />
                </div>
                <div className="grid space-y-4 md:grid-cols-2 md:space-x-12 ">
                    <div>
                        <LazyLoadImage
                            src={HeroImagePapandayan}
                            alt=""
                            className="items-center"
                            placeholderSrc={ImagePlaceHolder}
                        />
                    </div>
                    <div className="space-y-4">
                        <h2 className="mb-4 text-xl font-bold text-black font-dmsans dark:text-slate-100">
                            PAPANDAYAN ROOM
                        </h2>
                        <p className="font-dmsans text-slate-500">
                            Ruangan dengan meja podcast wide atau head to head
                            dan interview style lengkap dengan kekedapan ruangan
                            buat rekaman video atau podcast mu makin
                            professional.
                        </p>
                        <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                            <li>Kapasitas ruangan 4 pax</li>
                            <li>90% Kedap ruangan 95%</li>
                            <li>Kelengkapan equipment 100%</li>
                            <li>Design dan property dapat disesuaikan</li>
                        </ul>
                        <div className="grid grid-cols-2 gap-4 md:gap-4 md:grid-cols-4">
                            {roomDataPapandayan.map(
                                (roomDataPapandayan, index) => (
                                    <LazyLoadComponent>
                                        <div
                                            key={index}
                                            className="flex items-center justify-center"
                                        >
                                            <Lightbox
                                                thumbnail={
                                                    roomDataPapandayan.thumbnail
                                                }
                                                image={roomDataPapandayan.src}
                                                description={
                                                    roomDataPapandayan.description
                                                }
                                            />
                                        </div>
                                    </LazyLoadComponent>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid mt-32 space-y-4 md:grid-cols-2 md:space-x-12">
                    <div className="space-y-4">
                        <h2 className="mt-6 mb-4 text-xl font-bold text-black font-dmsans dark:text-slate-100">
                            GALUNGGUNG ROOM
                        </h2>
                        <p className="font-dmsans text-slate-500">
                            Ruangan dengan konsep lebih santai seperti di
                            ruangan rumah, di design secara minimalis, terdapat
                            sofa dan kursi serta meja yang dapat disesuaikan
                            dengan selera anda.
                        </p>
                        <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                            <li>Kapasitas ruangan 8 pax</li>
                            <li>90% Kedap ruangan 95%</li>
                            <li>Kelengkapan equipment 100%</li>
                            <li>Design dan property dapat disesuaikan</li>
                        </ul>
                        <div className="grid grid-cols-2 gap-4 md:gap-4 md:grid-cols-4">
                            {roomDataGalunggung.map(
                                (roomDataGalunggung, index) => (
                                    <LazyLoadComponent>
                                        <div
                                            key={index}
                                            className="flex items-center justify-center"
                                        >
                                            <Lightbox
                                                thumbnail={
                                                    roomDataGalunggung.thumbnail
                                                }
                                                image={roomDataGalunggung.src}
                                                description={
                                                    roomDataGalunggung.description
                                                }
                                            />
                                        </div>
                                    </LazyLoadComponent>
                                )
                            )}
                        </div>
                    </div>
                    <div className="order-first md:order-none">
                        <LazyLoadImage
                            src={HeroImageGalunggung}
                            alt=""
                            className="items-center"
                            placeholderSrc={ImagePlaceHolder}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
