/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {
    LazyLoadImage,
    trackWindowScroll,
} from "react-lazy-load-image-component";
import ImagePlaceholder from "../assets/image-placeholder.jpeg";
import Image1 from "../assets/Showcase/1.jpg";
import Image2 from "../assets/Showcase/2.jpg";
import Image3 from "../assets/Showcase/3.jpg";
import Image4 from "../assets/Showcase/4.jpg";
import Image5 from "../assets/Showcase/5.jpg";
import Image6 from "../assets/Showcase/6.jpg";
import Image7 from "../assets/Showcase/7.jpg";
import Image8 from "../assets/Showcase/8.jpg";
import Image9 from "../assets/Showcase/9.jpg";
import Image10 from "../assets/Showcase/10.jpg";
import Image11 from "../assets/Showcase/11.jpg";
import Image12 from "../assets/Showcase/12.jpg";
import Image13 from "../assets/Showcase/13.jpg";
import Image14 from "../assets/Showcase/14.jpg";
import Image15 from "../assets/Showcase/15.jpg";
import Image16 from "../assets/Showcase/16.jpg";
import Image17 from "../assets/Showcase/17.jpg";
import Image18 from "../assets/Showcase/18.jpg";
import Image19 from "../assets/Showcase/19.jpg";
import Image20 from "../assets/Showcase/20.jpg";

const Masonry = ({ scrollPosition }) => {
    const images = [
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Image6,
        Image7,
        Image8,
        Image9,
        Image10,
        Image11,
        Image12,
        Image13,
        Image14,
        Image15,
        Image16,
        Image17,
        Image18,
        Image19,
        Image20,
    ];
    return (
        <>
            <div className="gap-5 pb-10 mx-auto mb-10 space-y-5 md:columns-3 columns-2">
                {images.map((image, index) => (
                    <div class="relative overflow-hidden bg-no-repeat">
                        <LazyLoadImage
                            key={index}
                            src={image}
                            alt={`Image ${index + 1}`}
                            placeholderSrc={ImagePlaceholder}
                            scrollPosition={scrollPosition}
                            className="transition duration-300 ease-in-out hover:scale-110"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default trackWindowScroll(Masonry);
