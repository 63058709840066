export const EquipmentData = [
    {
        title: "ASHLEY SUPER M8",
        desc: "Mixer yang diproduksi khusus oleh ASHLEY memiliki 8 channel untuk rekaman suara dengan menghasilkan suara yang profesional untuk rekaman podcastmu.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Ashley.jpg"),
    },
    {
        title: "Boya By-m1000",
        desc: "wide frequency response, excel-lent signal-to-noise ratio, -10 dB Pad Switch and 80 Hz high-pass filter switch  yang kami sediakan tidak hanya untuk suara jernih, professional, dan berkualitas.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Boya-M100.jpg"),
    },
    {
        title: "GVM 800D-RGB LED",
        desc: "Lampu LED untuk mengatur mood ruangan sesuai karakteristikmu dengan ratusan pilihan warna yang bisa dikombinasi dari 2 sisi.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-GVM.jpg"),
    },
    {
        title: "SONY E-Mount A7ii",
        desc: "Kamera vlogging terbaik dengan kualitas performa tingkat tinggi dilengkapi lensa fix Sony E 50mm F/1.8 OSS , lensa zoom 25-200mm dan Lensa Zoom Sony 70-200mm",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Sony-EMount.jpg"),
    },
    {
        title: "Boya BY-WM4 PRO-K2",
        desc: "Michropon Digital Wireless, yang memberikan kejernihan suara serta kualitas terbaik dalam rekaman video anda",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Boya-WM4.jpg"),
    },
    {
        title: "Godox SL60W",
        desc: "Key light dari Godox dengan honeycomb grid dan double diffuser membuat penerangan dan contrast terbaik video kontenmu.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Godox.jpg"),
    },
    {
        title: "FEELWORLD®️",
        desc: "Switch Live dan output HDMI melalui USB untuk live streaming dengan Pengalih Video Multikamera LIVEPRO L1 ini dengan 4 x Input HDMI & Streaming USB dari FeelWorld.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Feelworld.jpg"),
    },
    {
        title: "FeiyuTech Scorp-C",
        desc: "Handheld gimbal terbaik untuk smoothness shot rekaman dokumentasi, produk, atau video klipmu.",
        image: require("../assets/Equipment/Injabar-Studio-Podcast-Feiyutech.jpg"),
    },
];
