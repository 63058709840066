export const PricingData = [
    {
        title: "Studio Pocast Talkshow",
        price: "Rp. 750.000",
        per: "3 Jam",
        item: [
            {
                item: "Durasi 3 Jam",
            },
            {
                item: "Kamera Sony A7II",
            },
            {
                item: "Lighting",
            },
            {
                item: "Microphone",
            },
            {
                item: "Mixer",
            },
            {
                item: "Soundcard",
            },
        ],
    },
    {
        title: "Full Studio Podcast Talkshow",
        price: "Rp. 2.350.000",
        per: "3 Jam",
        item: [
            {
                item: "Durasi 3 Jam",
            },
            {
                item: "3 Kamera Sony A7II",
            },
            {
                item: "Lighting",
            },
            {
                item: "Microphone",
            },
            {
                item: "Mixer",
            },
            {
                item: "Soundcard",
            },
            {
                item: "Editing Video Max 40 Menit",
            },
            {
                item: "Uploading",
            },
            {
                item: "Copywriting",
            },
            {
                item: "Thumbnail Grafis",
            },
        ],
    },
    {
        title: "Live Stream Studio",
        price: "Rp. 2.350.000",
        per: "Jam",
        item: [
            {
                item: "3 Kamera Sony A7II",
            },
            {
                item: "Lighting",
            },
            {
                item: "Microphone",
            },
            {
                item: "Mixer",
            },
            {
                item: "Mixer",
            },
            {
                item: "Soundcard",
            },
            {
                item: "Software Stream",
            },
            {
                item: "Technical Support",
            },
            {
                item: "Studio Layout",
            },
            {
                item: "Internet Highspeed",
            },
        ],
    },
    {
        title: "Live Stream Event / Outdoor",
        price: "Rp. 850.000",
        per: "Jam",
        item: [
            {
                item: "3 Kamera Sony A7II",
            },
            {
                item: "Lighting",
            },
            {
                item: "Microphone",
            },
            {
                item: "Mixer",
            },
            {
                item: "Mixer",
            },
            {
                item: "Soundcard",
            },
            {
                item: "Software Stream",
            },
            {
                item: "Technical Support",
            },
        ],
    },
];
