/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HeadingTag from "../shared/HeadingTag";
import PricingCard from "../shared/PricingCard";
export default function Pricing() {
    return (
        <div
            className="w-full p-10 px-4 py-32 bg-slate-100 dark:bg-gray-800"
            id="pricing"
        >
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag
                        text="Pricing"
                        heading="Paket terbaik yang kami tawarkan"
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-4">
                    <PricingCard />
                </div>
            </div>
        </div>
    );
}
