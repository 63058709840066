/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import HeadingTag from "../shared/HeadingTag";
import {} from "react-feather";
import { MapPin, Phone, Mail } from "react-feather";

export default function Contact() {
    return (
        <div className="w-full px-4 py-20 dark:bg-gray-900" id="contact">
            <div className="mx-auto lg:max-w-7xl">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <div>
                        <HeadingTag
                            text="Contact Us"
                            heading="Hubungi kami sekarang, dapatkan penawaran khusus yang terbatas ⚡️"
                        />
                        <ul className="space-y-8 list-inside text-slate-500 font-dmsans">
                            <li className="flex items-center">
                                <MapPin size={22} className="mr-4" />
                                Jl. Banda No.40, Citarum, Kec. Bandung Wetan,
                                <br />
                                Kota Bandung, Jawa Barat 40115
                            </li>
                            <li className="flex items-center">
                                <Phone size={22} className="mr-4" />
                                +62-811-2222-1050
                            </li>
                            <li className="flex items-center">
                                <Mail size={22} className="mr-4" />
                                raumstudiocreative@gmail.com
                            </li>
                        </ul>
                    </div>
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.8847057955304!2d107.61263142612604!3d-6.904388479054103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e77becab2737%3A0xc16efdf8303e1def!2sInjabar%20UNPAD!5e0!3m2!1sen!2sid!4v1675973610752!5m2!1sen!2sid"
                            frameborder="0"
                            style={{ border: 0 }}
                            aria-hidden="false"
                            tabindex="0"
                            className="w-full h-full rounded-xl"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
