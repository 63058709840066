/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
    RiFacebookFill,
    RiInstagramFill,
    RiTwitterFill,
    RiYoutubeFill,
} from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
export default function Footer() {
    return (
        <div className="w-full px-4 pt-10 dark:text-white bg-slate-50 dark:bg-gray-800 font-dmsans">
            <div className="p-10 mx-auto lg:max-w-7xl">
                <h1 className="text-2xl font-bold text-center">INJABAR</h1>
                <div className="grid w-full grid-cols-3 gap-5 mx-auto mt-10 md:w-fit lg:w-fit lg:grid-cols-6 md:grid-cols-3">
                    <a
                        href="#about"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        About
                    </a>
                    <a
                        href="#room"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        Room
                    </a>
                    <a
                        href="#equipment"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        Equipment
                    </a>
                    <a
                        href="#gallery"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        Gallery
                    </a>
                    <a
                        href="#pricing"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        Pricing
                    </a>
                    <a
                        href="#contact"
                        className="font-normal font-dmsans hover:text-blue-500 text-slate-600"
                    >
                        Contact
                    </a>
                </div>
                <div className="flex items-center justify-center mx-auto mt-10 text-center">
                    <div className="flex items-center space-x-8">
                        <a
                            href="https://web.facebook.com/injabarunpad/"
                            target="_blank"
                        >
                            <RiFacebookFill
                                size={20}
                                className="transition duration-300 text-slate-600 hover:text-blue-500"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/injabarunpad/"
                            target="_blank"
                        >
                            <RiInstagramFill
                                size={20}
                                className="transition duration-300 text-slate-600 hover:text-blue-500"
                            />
                        </a>
                        <a
                            href="https://www.twitter.com/injabarunpad"
                            target="_blank"
                        >
                            <RiTwitterFill
                                size={20}
                                className="transition duration-300 text-slate-600 hover:text-blue-500"
                            />
                        </a>
                        <a href="#">
                            <FaTiktok
                                size={20}
                                className="transition duration-300 text-slate-600 hover:text-blue-500"
                            />
                        </a>
                        <a
                            href="https://www.youtube.com/@INJABARTV"
                            target="_blank"
                        >
                            <RiYoutubeFill
                                size={20}
                                className="transition duration-300 text-slate-600 hover:text-blue-500"
                            />
                        </a>
                    </div>
                </div>
                <div className="mx-auto mt-10 text-center text-slate-500">
                    © 2023 Injabar Creative Studio
                </div>
            </div>
        </div>
    );
}
