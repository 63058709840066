import React from "react";
import HeadingTag from "../shared/HeadingTag";
import { OtherPackagePricingCard } from "../shared/OtherPackageCard";

export default function Other() {
    return (
        <div className="w-full px-4 py-20 dark:bg-gray-900">
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag
                        text="Other Package"
                        heading="Paket lain yang kami tawarkan untuk meningkatkan performa bisnis anda"
                    />
                </div>
                <div className="grid grid-cols-1 gap-4 md:gap-9 md:grid-cols-3">
                    <OtherPackagePricingCard />
                </div>
            </div>
        </div>
    );
}
