import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Hero from "./components/section/Hero";
import About from "./components/section/About";
import Room from "./components/section/Room";
import Equipment from "./components/section/Equipment";
import Pricing from "./components/section/Pricing";
import Showcase from "./components/section/Showcase";
import Other from "./components/section/Other";
import Contact from "./components/section/Contact";
import Footer from "./components/section/Footer";
import MetaTags from "react-meta-tags";
import TagManager from "react-gtm-module";

import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

const tagManagerArgs = {
    gtmId: "G-LZ41ZH7V19",
};

TagManager.initialize(tagManagerArgs);

function App() {
    return (
        <div className="wrapper">
            <MetaTags>
                <title>
                    Sewa Studio Podcast Bandung - Injabar Cretive Studio
                </title>

                <meta
                    name="keywords"
                    content="Sewa Studio Podcast, Sewa Studio Podcast Bandung, Injabar, Injabar Unpad, Injabar Unpad Bandung, Injabar Creative Studio, Podcast, Podcast Studio, Podcast Studio Bandung"
                />
                <meta name="author" content="Injabar Creative Studio" />

                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://creativestudioinjabar.com/"
                />

                <meta
                    name="title"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio"
                />
                <meta
                    name="description"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio adalah ruang studio multifungsi dengan fasilitas dan peralatan rekaman lengkap yang dapat digunakan sebagai ruang podcast atau event online dan ideal bagi kalangan profesional maupun non-profesional."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://creativestudioinjabar.com/"
                />
                <meta
                    property="og:title"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio"
                />
                <meta
                    property="og:description"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio adalah ruang studio multifungsi dengan fasilitas dan peralatan rekaman lengkap yang dapat digunakan sebagai ruang podcast atau event online dan ideal bagi kalangan profesional maupun non-profesional."
                />
                <meta
                    property="og:image"
                    content="../src/components/assets/HeroBanner.jpg"
                />

                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:url"
                    content="https://creativestudioinjabar.com/"
                />
                <meta
                    property="twitter:title"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio"
                />
                <meta
                    property="twitter:description"
                    content="Sewa Studio Podcast Bandung - Injabar Cretive Studio adalah ruang studio multifungsi dengan fasilitas dan peralatan rekaman lengkap yang dapat digunakan sebagai ruang podcast atau event online dan ideal bagi kalangan profesional maupun non-profesional."
                />
                <meta
                    property="twitter:image"
                    content="../src/components/assets/HeroBanner.jpg"
                />
            </MetaTags>
            <div className="content">
                <div className="dark:bg-slate-90">
                    <Navbar />
                    <Hero />
                    <About />
                    <Room />
                    <Equipment />
                    <Showcase />
                    <Pricing />
                    <Other />
                    <Contact />
                    <Footer />
                    <FloatingWhatsApp
                        showPopup="false"
                        popupMessage="Hi, ada yang bisa kami bantu?"
                        size="50px"
                        phone="6281122221050"
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
