import React from "react";
import HeadingTag from "../shared/HeadingTag";
import EquipmentCard from "../shared/EquipmentCard";
import { LazyLoadComponent } from "react-lazy-load-image-component";

export default function Equipment() {
    return (
        <div className="w-full px-4 py-24 dark:bg-gray-900" id="equipment">
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag
                        text="Our Equipment"
                        heading="Equipment terbaik yang kami sediakan untuk menghasilkan kualitas terbaik"
                    />
                </div>
                <div className="grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4">
                    <LazyLoadComponent>
                        <EquipmentCard />
                    </LazyLoadComponent>
                </div>
            </div>
        </div>
    );
}
