import React from "react";

const HeadingTag = ({ text, heading }) => (
    <div className="mb-8 md:mb-12">
        <div className="flex items-center my-4 mb-4">
            <div className="w-8 h-1 bg-blue-500"></div>
            <h4 className="mx-4 text-blue-500 text-md font-dmsans ">{text}</h4>
        </div>
        <h1 className="max-w-2xl text-2xl font-bold md:text-3xl font-dmsans dark:text-slate-100">
            {heading}
        </h1>
    </div>
);

export default HeadingTag;
