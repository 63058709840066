import PapandayanSatu from "../assets/Room/papandayan/1.jpg";
import PapandayanDua from "../assets/Room/papandayan/2.jpg";
import PapandayanTiga from "../assets/Room/papandayan/3.jpg";
import PapandayanEmpat from "../assets/Room/papandayan/4.jpg";
import PapandayanLima from "../assets/Room/papandayan/5.jpg";
import PapandayanEnam from "../assets/Room/papandayan/6.jpg";
import PapandayanTujuh from "../assets/Room/papandayan/7.jpg";
import PapandayanDelapan from "../assets/Room/papandayan/8.jpg";

import ThumbnailPapandayanSatu from "../assets/Room/papandayan/Thumbnail/1.png";
import ThumbnailPapandayanDua from "../assets/Room/papandayan/Thumbnail/2.png";
import ThumbnailPapandayanTiga from "../assets/Room/papandayan/Thumbnail/3.png";
import ThumbnailPapandayanEmpat from "../assets/Room/papandayan/Thumbnail/4.png";
import ThumbnailPapandayanLima from "../assets/Room/papandayan/Thumbnail/5.png";
import ThumbnailPapandayanEnam from "../assets/Room/papandayan/Thumbnail/6.png";
import ThumbnailPapandayanTujuh from "../assets/Room/papandayan/Thumbnail/7.png";
import ThumbnailPapandayanDelapan from "../assets/Room/papandayan/Thumbnail/8.png";

import GalunggungSatu from "../assets/Room/galunggung/1.jpg";
import GalunggungDua from "../assets/Room/galunggung/2.jpg";
import GalunggungTiga from "../assets/Room/galunggung/3.jpg";
import GalunggungEmpat from "../assets/Room/galunggung/4.jpg";
import GalunggungLima from "../assets/Room/galunggung/5.jpg";
import GalunggungEnam from "../assets/Room/galunggung/6.jpg";
import GalunggungTujuh from "../assets/Room/galunggung/7.jpg";
import GalunggungDelapan from "../assets/Room/galunggung/8.jpg";

import ThumbnailGalunggungSatu from "../assets/Room/galunggung/Thumbnail/1.png";
import ThumbnailGalunggungDua from "../assets/Room/galunggung/Thumbnail/2.png";
import ThumbnailGalunggungTiga from "../assets/Room/galunggung/Thumbnail/3.png";
import ThumbnailGalunggungEmpat from "../assets/Room/galunggung/Thumbnail/4.png";
import ThumbnailGalunggungLima from "../assets/Room/galunggung/Thumbnail/5.png";
import ThumbnailGalunggungEnam from "../assets/Room/galunggung/Thumbnail/6.png";
import ThumbnailGalunggungTujuh from "../assets/Room/galunggung/Thumbnail/7.png";
import ThumbnailGalunggungDelapan from "../assets/Room/galunggung/Thumbnail/8.png";

export const roomDataPapandayan = [
    {
        src: PapandayanSatu,
        thumbnail: ThumbnailPapandayanSatu,
        description: "Gambar 1",
    },
    {
        src: PapandayanDua,
        thumbnail: ThumbnailPapandayanDua,
        description: "Gambar 2",
    },
    {
        src: PapandayanTiga,
        thumbnail: ThumbnailPapandayanTiga,
        description: "Gambar 3",
    },
    {
        src: PapandayanEmpat,
        thumbnail: ThumbnailPapandayanEmpat,
        description: "Gambar 3",
    },
    {
        src: PapandayanLima,
        thumbnail: ThumbnailPapandayanLima,
        description: "Gambar 3",
    },

    {
        src: PapandayanEnam,
        thumbnail: ThumbnailPapandayanEnam,
        description: "Gambar 3",
    },
    {
        src: PapandayanTujuh,
        thumbnail: ThumbnailPapandayanTujuh,
        description: "Gambar 3",
    },

    {
        src: PapandayanDelapan,
        thumbnail: ThumbnailPapandayanDelapan,
        description: "Gambar 3",
    },
];

export const roomDataGalunggung = [
    {
        src: GalunggungSatu,
        thumbnail: ThumbnailGalunggungSatu,
        description: "Gambar 1",
    },
    {
        src: GalunggungDua,
        thumbnail: ThumbnailGalunggungDua,
        description: "Gambar 2",
    },
    {
        src: GalunggungTiga,
        thumbnail: ThumbnailGalunggungTiga,
        description: "Gambar 3",
    },
    {
        src: GalunggungEmpat,
        thumbnail: ThumbnailGalunggungEmpat,
        description: "Gambar 3",
    },
    {
        src: GalunggungLima,
        thumbnail: ThumbnailGalunggungLima,
        description: "Gambar 3",
    },

    {
        src: GalunggungEnam,
        thumbnail: ThumbnailGalunggungEnam,
        description: "Gambar 3",
    },
    {
        src: GalunggungTujuh,
        thumbnail: ThumbnailGalunggungTujuh,
        description: "Gambar 3",
    },

    {
        src: GalunggungDelapan,
        thumbnail: ThumbnailGalunggungDelapan,
        description: "Gambar 3",
    },
];
