import React, { useState } from "react";

const Lightbox = ({ thumbnail, image, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="hover:cursor-zoom-in">
            <img
                src={thumbnail}
                alt=""
                className="w-[144px] h-[144px]"
                onClick={() => setIsOpen(true)}
            />
            {isOpen && (
                <div
                    className="fixed top-0 left-0 flex items-center justify-center w-full h-full p-20 bg-gray-900 bg-opacity-75 "
                    onClick={() => setIsOpen(false)}
                >
                    <div className="p-2 bg-white rounded-lg md:p-6 md:w-1/2 h-fit">
                        <img src={image} alt="" className="w-full h-full" />
                        {/* <p className="mt-4 text-gray-700">{description}</p> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Lightbox;
