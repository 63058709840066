/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Check } from "react-feather";
import { PricingData } from "../data/pricing.js";
import { BsWhatsapp } from "react-icons/bs";
const PricingCard = () => {
    return (
        <>
            {PricingData.map((data, key) => {
                return (
                    <div
                        key={key}
                        className="p-8 space-y-4 bg-white rounded-xl hover:shadow-sm hover:transform dark:bg-gray-900 h-fit"
                    >
                        <h3 className="text-lg font-bold font-dmsans dark:text-slate-100">
                            {data.title}
                        </h3>
                        <ul className="space-y-2 list-inside text-slate-500">
                            {data.item.map((listItem, index) => (
                                <li
                                    key={index}
                                    className="flex items-center text-left"
                                >
                                    <Check
                                        color="green"
                                        size={18}
                                        className="mr-4"
                                    />
                                    {listItem.item}
                                </li>
                            ))}
                        </ul>
                        <hr className="mt-4 " />
                        <p className="text-sm font-dmsans text-slate-500">
                            Start from
                        </p>
                        <h3 className="text-2xl font-bold text-yellow-500 font-dmsans">
                            {data.price}{" "}
                            <span className="text-sm font-normal text-slate-500">
                                / {data.per}
                            </span>
                        </h3>
                        <hr className="mt-4 " />
                        <div className="">
                            <a
                                href="https://wa.me/6281122221050"
                                target="_blank"
                            >
                                <button className="flex items-center justify-center w-full p-4 text-white transition duration-300 ease-linear rounded-md bg-slate-600 font-dmsans hover:bg-green-700">
                                    <BsWhatsapp className="mr-4" />
                                    Hubungi Kami
                                </button>
                            </a>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default PricingCard;
