/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Switcher from "./Switcher";
import Logo from "./assets/logo.png";
export default function Navbar() {
    const [navbar, setNavbar] = useState(false);

    return (
        <nav className="w-full bg-transparent dark:bg-slate-900 dark:text-white">
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <a href="javascript:void(0)">
                            <div className="flex items-center">
                                <img src={Logo} alt="" className="w-14" />
                                <h2 className="w-1/2 font-bold leading-tight text-md font-dmsans">
                                    INJABAR STUDIO
                                </h2>
                            </div>
                        </a>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 "
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 "
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0  ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center justify-center space-y-6 text-black md:flex md:space-x-10 md:space-y-0 font-dmsans dark:text-white">
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#home">Home</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#about">About</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#room">Room</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#equipment">Equipment</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#gallery">Gallery</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#pricing">Pricing</a>
                            </li>
                            <li className="transition duration-200 hover:text-blue-500">
                                <a href="#contact">Contact</a>
                            </li>
                            <li>
                                <Switcher />
                            </li>
                        </ul>

                        <div className="mt-8 space-y-4 lg:hidden md:inline-block">
                            <a
                                target="_blank"
                                href="https://injabar.unpad.ac.id"
                                className="inline-block w-full px-4 py-3 text-center text-white transition duration-200 bg-blue-500 rounded-full font-dmsans hover:bg-blue-700"
                            >
                                Injabar Website
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hidden space-x-2 md:inline-block">
                    <a
                        target="_blank"
                        href="https://injabar.unpad.ac.id"
                        className="px-4 py-3 text-white transition duration-200 bg-blue-500 rounded-full font-dmsans hover:bg-blue-700"
                    >
                        Injabar Website
                    </a>
                </div>
            </div>
        </nav>
    );
}
