import React from "react";
import { EquipmentData } from "../data/equipment.js";

const EquipmentCard = () => {
    return (
        <>
            {EquipmentData.map((data, key) => {
                return (
                    <div key={key}>
                        <div
                            className="md:w-[310px] md:h-[310px] bg-cover bg-center bg-no-repeat w-44 h-44 mb-4"
                            style={{
                                backgroundImage: `url(${data.image})`,
                            }}
                        ></div>
                        <h3 className="mb-2 font-bold text-black uppercase text-md md:text-lg lg:text-lg fond-dmsans dark:text-slate-100">
                            {data.title}
                        </h3>
                        <p className="text-sm text-justify md:text-md font-base text-slate-500">
                            {data.desc}
                        </p>
                    </div>
                );
            })}
        </>
    );
};
export default EquipmentCard;
