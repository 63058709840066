import React from "react";
import {
    Database,
    Code,
    AtSign,
    Camera,
    Instagram,
    Archive,
} from "react-feather";

export const OtherPackagePricingCard = () => {
    return (
        <>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <Database
                                size={18}
                                className="text-blue-400 transition duration-500 dark:text-white"
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            Start from
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Rp.3.000.000
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-white">
                    Web Development
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li> Company Profile statis Max 4 Halaman</li>
                    <li>Web Design</li>
                    <li>Domain .com 1 Tahun</li>
                    <li>Hosting 1 Tahun</li>
                    <li>Email Profesional</li>
                    <li>Revisi Design 2 kali</li>
                </ul>
            </div>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <Code
                                className="text-blue-400 dark:text-white"
                                size={18}
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            Start from
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Rp.5.000.000
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-slate-100">
                    App Development
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li>Start from 5.000.000</li>
                    <li>App Design</li>
                    <li>Domain .com 1 Tahun</li>
                    <li>Hosting 1 Tahun</li>
                    <li>Email Profesional</li>
                    <li>Revisi Design 2 kali</li>
                </ul>
            </div>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <AtSign
                                className="text-blue-400 dark:text-white"
                                size={18}
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            Start from
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Rp.20.000.000
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-slate-100">
                    Media Handling
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li>News Coverage</li>
                    <li>Produksi News Release 1 Bulan 4 Artikel</li>
                    <li>Publish 1 Minggu 1 Kali dalam 1 Bulan</li>
                    <li>10-15 Media Massa</li>
                    <li>2x Revisi Berita</li>
                </ul>
            </div>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <Camera
                                className="text-blue-400 dark:text-white"
                                size={18}
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            Start from
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Rp.1.500.000
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-slate-100">
                    Photo & Video
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li>2-3 Jam kerja</li>
                    <li>1 Photographer</li>
                    <li>1 Videographer*</li>
                    <li>Crew</li>
                    <li>80+ Foto edit</li>
                    <li>Video Cinematic 1 Min*</li>
                </ul>
            </div>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <Instagram
                                className="text-blue-400 dark:text-white"
                                size={18}
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            Start from
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Rp.1.300.000
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-slate-100">
                    Social Media Promotion
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li>Up to 70 desain konten</li>
                    <li>Up to 60 desain feed</li>
                    <li>Up to 10 desain story</li>
                    <li>Up to 14 post carousel</li>
                    <li>Free posting setiap hari</li>
                    <li>Report bulanan*</li>
                </ul>
            </div>
            <div className="p-8 space-y-6 duration-300 ease-in-out border-2 rounded-xl hover:shadow-sm hover:transform border-slate-100 dark:border-slate-800 h-96 hover:-translate-y-6 dark:bg-slate-800">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg font-bold font-dmsans">
                        <div className="p-4 transition duration-500 rounded-full bg-blue-50 dark:bg-slate-900 ">
                            <Archive
                                className="text-blue-400 dark:text-white"
                                size={18}
                            />
                        </div>
                    </h3>
                    <div className="text-end">
                        <small className="text-base font-normal font-dmsans text-slate-500">
                            By Request
                        </small>
                        <h3 className="text-lg font-bold text-blue-500 font-dmsans">
                            Contact Us
                        </h3>
                    </div>
                </div>
                <h3 className="text-xl font-bold font-dmsans dark:text-slate-100">
                    Merchandise
                </h3>
                <ul className="leading-8 list-disc list-inside font-dmsans text-slate-500">
                    <li>Clothing & Apparel Design</li>
                    <li>Cup or Mug Design</li>
                    <li>Sticker Design</li>
                    <li>Packaging Product</li>
                    <li>Product Label</li>
                </ul>
            </div>
        </>
    );
};
