import React from "react";
import HeadingTag from "../shared/HeadingTag";
import Masonry from "../shared/Masonry";

export default function Showcase() {
    return (
        <div className="w-full px-4 py-36 dark:bg-gray-900" id="gallery">
            <div className="mx-auto lg:max-w-7xl">
                <div>
                    <HeadingTag text="Gallery" heading="Showcase" />
                </div>
                <Masonry />
            </div>
        </div>
    );
}
